import "./styles/style.css";
import ReactDOM from "react-dom/client";
import Game from "./components/Game";
import MainMenu from "./components/MainMenu";
import { BrowserRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';
import { rune_modes } from "./utilities/rune_modes"
import React from "react";


const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
  <>
    <HashRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<MainMenu />} />          
          <Route path="/mode/:id" element={<Game data={rune_modes} />} />
        </Routes>
      </div>
    </HashRouter>
  </>
);
