
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/style.css";
import React, { useEffect, useMemo, useState } from "react";
import { DB_URL } from "../utilities/constants";
import axios from "axios";
import PopupMenu from "./PopupMenu";
import RuneFall from "./RuneFall";
import Particles from "./Particles";
// import RuneDetails from "./RuneDetails";
import { SpriteMaterial, Sprite, TextureLoader } from 'three';

import useStore from '../utilities/useStore';
import { Environment, OrbitControls } from "@react-three/drei";
import { useRef } from "react";
import gsap from "gsap";
import { Perf } from "r3f-perf";

export default function Game({ props }) {
  const [backEndData, setbackEndData] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [mode, setMode] = useState({});
  const [instructionText, setInstructionText] = useState("");

  const setNavigate = useStore((state) => state.setNavigate);
  const navigate = useNavigate();
  const setConfigData = useStore((state) => state.setConfigData);
  const configData = useStore((state) => state.configData);

 
  const reloadState = useStore((state) => state.reloadState);
  const setReloadState = useStore((state) => state.setReloadState);
  useEffect(()=>{
    setReloadState(Math.random());
  },[id]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate, setNavigate]);

  useEffect(() => {
    fetch(`../modes/mode${id}.json`)
      .then(response => response.json())
      .then(jsonData => setMode(jsonData))
      .catch(error => console.error('Error loading the data', error));
  }, [id]);

  useEffect(() => {
    fetch(`../config.json`)
      .then(response => response.json())
      .then(jsonData => {setConfigData(jsonData); })
      .catch(error => console.error('Error loading the data', error));
  }, []);

  // console.log("json data is ", configData);
  
  useEffect(() => {
    if(configData?.DB_URL)
    axios
      .get(`${configData?.DB_URL}`)
      .then((response) =>{ setbackEndData(response.data);
        // console.log("back end data is ", response.data);
      })
      .catch((error) => setError(error));
  }, [configData]);

  const ResizeHandler = () => {
    const { camera, gl } = useThree();
  
    useEffect(() => {
      const handleResize = () => {
        const scrolDom = document.getElementById('scrollControlls');

        let windowWidth = window.innerWidth;
        let windowHeight = window.innerHeight;        

        if(windowWidth > 960)
          windowWidth = 960;

        // Check if the window is in landscape or portrait mode
        if ((windowWidth / windowHeight) > 1) {
          windowWidth = windowHeight;
          } else {
              windowHeight = windowWidth;
          }
        camera.aspect = 1;

        // console.log("Scroll dom ",scrolDom);
        if(scrolDom){
            scrolDom.style.maxWidth = windowWidth+"px";
            scrolDom.style.maxHeight = windowHeight+"px"  ;

            // scrolDom.style.right = "10px"
            // scrolDom.style.left = "17%"
            scrolDom.parentNode.parentNode.style.maxHeight = windowHeight+"px";
            scrolDom.parentNode.parentNode.style.transform = 'translate(-50%, -50%)';
            scrolDom.parentNode.parentNode.style.top = '50%';
            scrolDom.parentNode.parentNode.style.left = '50%';
          }
      
        let widthratio = windowWidth/ window.innerWidth;
        let heightratio = windowHeight/window.innerHeight;

        console.log("widthratio ", widthratio);
        console.log("heightratio ", heightratio);
        let rootid = document.getElementById('root');
        rootid.style.height = heightratio*100+'vh';
       rootid.style.width = widthratio*100+'%';

        camera.updateProjectionMatrix();
        gl.setSize(windowWidth, windowHeight);
      };
  
      window.addEventListener('resize', handleResize);
      
      handleResize(); // Call initially to set the size based on the initial window size
  
      return () => window.removeEventListener('resize', handleResize);
    }, [camera, gl]);
  
    return null;
  };


  function CameraSetup({ position, target, fov }) {
    const { camera } = useThree();
    useEffect(() => {
      camera.position.set(...position);
      camera.lookAt(...target);
      camera.fov = fov;
    }, [camera, position, target]);
    return null;
  }

function showInstruction(count){
  // console.log("Remaining count ", count);
  if(count == 0)
    setInstructionText("")
  else if(mode.runes?.length ==1)
    setInstructionText(`Select a rune`)
  else if(count == 1)
    setInstructionText("Select last rune")
  else if(count == mode.runes?.length)
    setInstructionText(`Select ${count} runes`)
  else
    setInstructionText(`Select ${count} more runes`)
}

  return (
    <>    
      <Canvas  gl={{ alpha: true }} style={{ background: 'transparent' }} >
        {/* <Perf/> */}
      
      <CameraSetup position={[0, 20, 15]} target={[0, 0, 0]} fov={70} />
      <ResizeHandler />
         <group  key={reloadState}>
         <Light/>
         <RuneFall  mode={mode} backEndData={backEndData} showInstruction={showInstruction}/>
         </group>
      </Canvas>
      <Popup data={backEndData} />  
     { instructionText.length >0 && <Instruction content={instructionText} />}
     <p>.</p>
    </>
  );
}

function Popup({ data }) {

  const isPopEnabled = useStore((state) => state.isPopEnabled);
  const showPopup = useStore((state) => state.showPopup);

  const handleClose = () => showPopup(false);
  return (
    <>
      <div className="App">
        <PopupMenu show={isPopEnabled} onClose={handleClose} />
      </div>     
    </>
  );
}

function Light(){

  const curtainRef = useRef();
  const [showCurtain, setShowCurtain] = useState(true);
  function InitScene() {
    curtainRef.current.opacity = 1;
    gsap.to(curtainRef.current, {
      opacity: 0, // Target intensity
      duration: 3, // Duration in seconds
      ease: "expo.inOut", // Easing function
      onComplete: ()=> setShowCurtain(false)
    });
  }

  useEffect(() => {
    InitScene();
    // console.log("game load init");
  }, []);
  return<> 
  {/* <OrbitControls/> */}
   <directionalLight   position={[-20, 15, 10]} intensity={7} color="#FFA07A" />
    
     { showCurtain && (<mesh position={[0, 10, 18]}  rotation-x={(-Math.PI / 2) * 0.55} scale={80} >
          <planeGeometry />
          <meshStandardMaterial  color="black" transparent={true} ref={curtainRef} />
      </mesh>)}
      <Environment  preset="forest"  />
  </>
}

function Instruction ({content}){
  return (
    <>
      <div style={{ 
      position: 'absolute', 
      top: 0, 
      width: '100%', 
      backgroundColor: 'rgba(255, 255, 255, 0.4)', 
      zIndex: 10 
    }} className="d-flex justify-content-center align-items-center py-2">
        {/* <h2 className="display-3 text-center">{content}</h2> */}
        <p className="h2">{content}</p>
      </div>
    </>
  );
}

