import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useControls } from 'leva'
import * as THREE from "three";
import gsap from 'gsap';

export function HandAnimation({position}) {
  const handAnimation = useGLTF('../hand_animation.glb');
  const { actions } = useAnimations(handAnimation.animations, handAnimation.scene);
const handRef = useRef();
  useEffect(() => {
    const actionName = Object.keys(actions)[0]; // Automatically get the first animation name
    const action = actions[actionName];
    if (action) {
      action.setLoop(THREE.LoopOnce); // Play the animation once
      action.clampWhenFinished = true; // Prevent resetting after the animation finishes
      action.timeScale = 0.75; // Set the desired timescale (0.5 means half speed, 2 means double speed, etc.)
      action.time = 0;
      setTimeout(()=>{
        gsap.to(handRef.current?.position, {
          x: 0, y: -4.5, z: 7.5,
          duration: 1.5, ease: "expo.inOut",
          
        })
      },2000)
      action.play(); // Play the animation
    } else {
      console.error("No animation found.");
    }
  }, [actions]);

  return (
    <primitive
      object={handAnimation.scene}
      scale={45}
      position={[0, +12, -5]}
      rotation-y={Math.PI}
      ref={handRef}
    />
  );
}

