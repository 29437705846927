export const DB_URL =
  "https://kriyazone.com/pramod_ramdurg/klinglt/get_data.php";

export const RUNE_NAMES = [
  "Algiz",
  "Ansuz",
  "Berkano",
  "Dagaz",
  "Ehwaz",
  "Eihwaz",
  "Fehu",
  "Gebo",
  "Hagalaz",
  "Ingwaz",
  "Isa",
  "Jera",
  "Kenaz",
  "Laguz",
  "Mannaz",
  "Naudhiz",
  "Othala",
  "Perthro",
  "Raidho",
  "Sowilo",
  "Thurisaz",
  "Tiwaz",
  "Uruz",
  "Wunjo",
];
