import React from "react";
import { rune_modes } from "../utilities/rune_modes";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/popupMenu.css"
import useStore from "../utilities/useStore";

function PopupMenu({ show, onClose }) {
  const navigate = useNavigate();
  const setReloadState = useStore((state) => state.setReloadState);



  const handleCardClick = (id) => {
    setReloadState(Math.random());
    onClose();
    navigate(`/mode/${id}`);
  };

  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Mode</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto" style={{ maxHeight: '80vh' }}>
        {rune_modes.map((item, index) => (
          <Card
            key={index}
            className="mb-3"
            onClick={() => handleCardClick(item.id)}
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex align-items-center">
              <Image
                src={item.image}
                alt={item.title}
                className="card-img-top"
                style={{ width: "50px", height: "50px", marginRight: "10px" }}
              />
              <div className="card-body p-2" id="card-body">
                <h5 className="card-title mb-1" id="card-title">{item.title}</h5>
                <p className="card-text mb-0 text-truncate" id="card-text" >
                  {item.description}
                </p>
              </div>
            </div>
          </Card>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopupMenu;
