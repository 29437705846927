export const rune_modes = [
  {
    "id": 1,
    "title": "Rune des Tages",
    "description": "Odins Orakel - Hier können Sie Ihre heutige Tagesrune ziehen. Diese eine gezogene Rune zeigt Ihnen einen Überblick über die aktuelle Gesamt-Situation beziehungsweise zeigt das Thema mit dem Sie sich befassen sollten.",
    "runes":[
      {
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/tagesrune-402x241_shutterstock_344234789-Borys_Vasylenko.jpg"
  },
  {
    "id": 2,
    "title": "Zeit-Runen",
    "description": "Hier erfahren Sie mehr über Ihre jetzige Situation / Hauptproblem. Was müssen Sie tun, um an Ihr Ziel zu kommen? Es gibt Antwort auf die Entstehung Ihres jetzigen Problems und was Sie tun können um es zu lösen.",
    "runes":[
      {
        "description":"Rune: sie steht für die aktuelle Situation oder das aktuelle Problem",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: zeigt die notwendige Handlung",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: erlaubt einen Blick auf die neue Situation",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/zeit-rune-402x241_shutterstock_248465470-Romolo_Tavani.jpg"
  },
  {
    "id": 3,
    "title": "Zeit-Runen 2",
    "description": "Hier erfahren Sie mehr über Ihr Hauptproblem Ihres Leben. Wie ist das Problem entstanden, und was ist das Problem? Was bringt die Zukunft?",
    "runes":[
      {
        "description":"Rune: wie ist das Problem entstanden?",
        "personal_meaning":"bedeutung1v",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: was ist das Problem?",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: wie entwickelt sich die Situation weiter?",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/zeit-rune2-402x241_shutterstock_187536218-enrique_ramos.jpg"
  },
  {
    "id": 4,
    "title": "Vier-Runen Orakel",
    "description": "Diese Legung wird gern verwendet um Blockaden im Leben des Fragenden zu lösen. Es zeigt die jetzige Situation - wo liegen Blockaden und was muss getan werden, um sie zu lösen.",
    "runes":[
      {
        "description":"Rune: die aktuelle Situation",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: wo ist die Blockade?",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: was ist nun notwendig?",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: welches Ergebnis kann ich erwarten?",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/vier-runen-orakel-402x241_shutterstock_235968034-Elena_Pavlovich.jpg"
  },
  {
    "id": 5,
    "title": "Pro-und Contra-Runen",
    "description": "Hier haben Sie die Möglichkeit zwei verschiedene Wege in Ihrem Leben abzuwägen. Was spricht für den einen oder den anderen Weg?",
    "runes":[
      {
        "description":"Was spricht dafür?",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Was spricht dagegen?",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/vier-runen-orakel-402x241_shutterstock_235968034-Elena_Pavlovich.jpg"
  },
  {
    "id": 6,
    "title": "Das Kreuz der Runen",
    "description": "Diese Legung zeigt die jetzige Situation des Fragenden. Was ist in der Vergangenheit passiert? Welche Herausforderungen müssen gelöst werden, um ans Ziel zu kommen?",
    "runes":[
      {
        "description":"Rune: die Vergangenheit",
        "personal_meaning":"bedeutung1v",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: die aktuelle Situation",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: die Zukunft",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Grundlagen der aktuellen Situation",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: welche Herausforderung gilt es zu lösen?",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: das Resultat",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: der Lösungsansatz",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/das-kreuz-der-runen-402x241_shutterstock_459319870-Jojoo64.jpg"
  },
  {
    "id": 7,
    "title": "Runen des Schicksals",
    "description": "Bei dieser Legung wird die jetzige Situation genau dargestellt. Man sieht die Aufgaben die auf einen zukommen. Was soll getan und was vermieden werden in der jetzigen Situation? Hier bekommen Sie genaue Informationen über das was Sie lernen sollen.",
    "runes":[
      {
        "description":"Rune: das ist die bewusste Aufgabe des Fragenden",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: die unbewusste Aufgabe des Fragenden",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: die Hoffnungen und Ängste des Fragenden",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: das sollte unbedingt vermieden werden",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: das ist das Wichtigste, was getan werden sollte",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: dahin geht der Weg",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: was ist zu lernen auf diesem Weg",
        "personal_meaning":"bedeutung1z",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/schicksals-runen-402x241_shutterstock_208679314-almgren.jpg"
  },

  {
    "id": 8,
    "title": "Die Neun Welten Runen",
    "description": "Diese Legung beschreibt die jetzige Situation des Fragenden. Dinge die Sie blockieren. Was ist die Aufgabe, und was muss getan werden um ans Ziel zu kommen? Ausserdem sieht man die Blockaden und Ängste die einen nicht vorankommen lassen",
    "runes":[
      {
        "description":"Rune: der Fragende, das Ich",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: die momentane Situation, das Thema",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Kräfte, die unbewusst dagegen wirken",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Gefühl und Intuition",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Instinkte, die beachtet werden wollen",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Gedanken des Fragenden",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: was ist gerade besonders wichtig",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: Motivation, Antrieb",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      },
      {
        "description":"Rune: was ist durch Zweifel und Ängste blockiert",
        "personal_meaning":"bedeutung1",
        "general_meaning":"bedeutung"
      }
    ],
    "image": "https://www.zukunftsblick.ch/images/runen-die-neun-welten-402x241_shutterstock_204090667-Stephen_Orsillo.jpg"
  }
];