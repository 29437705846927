import React from "react";
import { rune_modes } from "../utilities/rune_modes";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";

const MainMenu = () => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/mode/${id}`);
  };

  return (
    <div className="container">
      <div className="row">
        {rune_modes.map((item) => (
          <div
            key={item.id}
            className="col-12 mb-4"
            onClick={() => handleCardClick(item.id)}
          >
            <Card style={{ cursor: "pointer" }}>
              <Row className="g-0">
                <Col md={4}>
                  <Card.Img variant="top" src={item.image} alt={name} />
                </Col>
                <Col md={8}>
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainMenu;
