import React, { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect } from "react";
import { Stage, useGLTF, OrbitControls, useTexture, Environment, ScrollControls, Scroll, useScroll, Html, Float } from "@react-three/drei";
import { Physics, RigidBody, CapsuleCollider, CuboidCollider, useRapier, } from "@react-three/rapier";
import { HandAnimation } from "./HandAnimation";

import * as THREE from "three"; // Import THREE.js
import { gsap } from "gsap";
import { useFrame, useThree } from "@react-three/fiber";
import {RuneDetails} from "./RuneDetails";
import { RUNE_NAMES } from "../utilities/constants";
import PopupMenu from "./PopupMenu";
import useStore from "../utilities/useStore";
import {isMobile} from 'react-device-detect';
import RunesAudioManager from './RunesAudioManager.js'

export default function RuneFall({ mode , backEndData, showInstruction}) {
  const { scene } = useGLTF("../runes/Rune1.glb");
  const runeStoneTexture = useTexture("../runes/maintex.jpg");
  const bgTexture = useTexture("../BG.jpg");
  const bgBlurTexture = useTexture("../BG_blur.jpg");

  const runeCount = 24;

  const [selectableRuneCount, setSelectableRuneCount] = useState(1);
  const [visibleRunes, setVisibleRunes] = useState( Array(runeCount).fill(false) );
  const [enableRuneOnClick, setRuneOnClick] = useState(false);
  const [kinematicRunes, setKinematicRunes] = useState( Array(runeCount).fill(false) );
  const [runeClicked, setRuneClicked] = useState(Array(runeCount).fill(false));
  const [selectedRunesDetails, setSelectedRunesDetails] = useState([]);
  const [runeSelectedCount, setRuneSelectedCount] = useState(0);
  const [scrollPageCount, setScrollPageCount] = useState(0);
  const [runeScale,setRuneScale] = useState(1);
  const [isRuneSelectionComplete, setRuneSelectionComplete] = useState(false);
  const [isRuneFallingComplete, setRuneFallingComplete] = useState(false);
  const bgMaterialRef = useRef();
  const configData = useStore((state) => state.configData);

 const symbolTextures = useTexture(Array.from({ length: configData?.RUNE_NAMES.length }, (_, i) => `../runes/rune_texture_with_name/${ configData?.RUNE_NAMES[i]}.png`));

 useEffect(() => {
   setSelectableRuneCount(mode.runes?.length);  
 }, [mode]);
 
 const audioManager = new RunesAudioManager();

 const AudioControl = () => {
   //console.log("audioplayer");
   const { camera } = useThree();
   useEffect(() => {
     camera.add(audioManager.getListener());
     const audioId = 'background';
     const audioUrl = '../audios/background-v1.mp3';
     audioManager.addAudio(audioId, audioUrl, { loop: true, volume: 0.025, });

     audioManager.addAudio('runefall', '../audios/initial-runes-drop.mp3', { loop: true, volume: 0.8, });
     audioManager.addAudio('runeclick', '../audios/rune-clicked.mp3', { loop: false, volume: 1, });
     audioManager.addAudio('runeschosen', '../audios/all-runes-chosen.mp3', { loop: false, volume: 1, });
     
     const interval = setInterval(() => {
       const audio = audioManager.audioMap.get(audioId);
       if (audio && audio.buffer) {
         audioManager.playAudio(audioId)
         clearInterval(interval);
       }
     }, 200);
     return () => {
       camera.remove(audioManager.getListener());
     };
   }, [camera]);
 };

 useEffect(() => {
  let time =0;
  if(runeSelectedCount == 0) time = 10000;
  else time = 4000;
  setTimeout(()=>{
    showInstruction(selectableRuneCount-runeSelectedCount); 
    setRuneFallingComplete(true);  
  },time)  
}, [selectableRuneCount, runeSelectedCount]);

  // Flip the textures on the y-axis
  useEffect(() => {
    if(symbolTextures)
    symbolTextures.forEach(texture => {
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.y = -1;
      texture.needsUpdate = true; // Ensure the texture updates
    });
  }, [symbolTextures]);

  const { camera } = useThree();

  const getRandomPositionInBag = (min, max) => {
    return [Math.random() * (max - min) + 1, 9, -1.5];
  };

  const getRandomVelocity = () => {
    return [
      0, // Random x velocity
      -30, // Random downward y velocity
      0, // Random z velocity
    ];
  };

   const [runeData, setRuneData] = useState(
    Array.from({ length: runeCount }, () => ({
      position: getRandomPositionInBag(0, 0.25), // Bag space dimensions
      velocity: getRandomVelocity(),
      opacity: 0
    }))
  );
  
  const runeFallDelay = 6500;//9500;

  useEffect(() => {
    const startFalling = () => {
      let cumulativeDelay = 0;
      audioManager.setPlaybackRate('runefall', 0.7);
      audioManager.playAudio('runefall');
      setTimeout(() => {
        audioManager.stopAudio('runefall');
      }, 2600);
      visibleRunes.forEach((_, index) => {
        let delay;
        if (index === 12) { delay = 0;} else { delay = 70; }
        cumulativeDelay += delay;
        setTimeout(() => {
          setVisibleRunes((prev) => {
            const newRunes = [...prev];
            newRunes[index] = true;
            return newRunes;
          });
          setRuneOnClick(true);
        }, cumulativeDelay);
      });
    };
    const timer = setTimeout(startFalling, runeFallDelay); // Delay the start of the falling animation by 5 seconds
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);


  useEffect(() => {
    if(enableRuneOnClick && selectableRuneCount === runeSelectedCount)
      {
        setRuneOnClick(false);
        hideRemainingRunes();
        showDetailsOfRune();
        setTimeout(()=>{
          if(isMobile){
          if (parseInt(selectableRuneCount) > 6)
            setScrollPageCount(parseInt(selectableRuneCount) + 8);
          else 
            setScrollPageCount(parseInt(selectableRuneCount) + 6);
          }
          else{
            if (parseInt(selectableRuneCount) > 6)
              setScrollPageCount(parseInt(selectableRuneCount) + 4);
            else 
              setScrollPageCount(parseInt(selectableRuneCount) + 2);
            }
          setRuneSelectionComplete(true);
    
          //hideRemainingRunes();
          audioManager.addAudio('runeschosen');
        },1000)
    }
  },[selectedRunesDetails,enableRuneOnClick]);

  const updateRuneOpacity = (index, newOpacity) => {
    setRuneData((prev) =>
      prev.map((rune, i) => i === index ? { ...rune, opacity: newOpacity } : rune )
    );
  };

  const handleRuneClick = (event, index, rigidBodyRef, primitiveRef) => {
    setSelectedRunesDetails((prev) => [...prev, { index, rigidBodyRef,primitiveRef }]);
    event.stopPropagation();
    setRuneOnClick(false);
    setKinematicRunes((prev) => {
      const newKinematicRunes = [...prev];
      newKinematicRunes[index] = true;
      return newKinematicRunes;
    });

    setRuneClicked((prev) => {
      const newRuneClicked = [...prev];
      newRuneClicked[index] = true;
      return newRuneClicked;
    });

    audioManager.playAudio('runeclick');

    setTimeout(() => {
      //console.log("kinematicRunes ", kinematicRunes);      
      if (rigidBodyRef.current) {
        highlightRunes(rigidBodyRef, index);
      }
    }, 100);
  };

  const highlightRunes = (rigidBodyRef, index) => {
    const runeMesh = rigidBodyRef.current;
    const timeline = gsap.timeline();
    const position = runeMesh.translation();
    let rotation = runeMesh.rotation();
  
    const targetPosition = new THREE.Vector3(0, 16, 10);
    const calculateTargetPosition2 = (index, totalObjects) => {
      const spacing =2; // Adjust the spacing between objects as needed
      const startX = (-(totalObjects - 1) * spacing) / 2; // Calculate starting position for centering
      return new THREE.Vector3(startX + index * spacing, 7, 10.5);
    };
  
    const targetPosition2 = calculateTargetPosition2(runeSelectedCount, selectableRuneCount); // Example for the first object
  
    setRuneSelectedCount(runeSelectedCount + 1);
  
    const direction = new THREE.Vector3();
    camera.getWorldDirection(direction);
    direction.negate(); // To make the object face the camera
  
    const targetQuaternion = new THREE.Quaternion().setFromEuler( new THREE.Euler((-Math.PI * 3) / 4, -Math.PI / 2, 0) );
    
    const targetQuaternion2 = new THREE.Quaternion().setFromEuler( new THREE.Euler(Math.PI /4, Math.PI / 2, 0) );
    const targetQuaternion3 = new THREE.Quaternion().setFromEuler( new THREE.Euler(0, Math.PI/2,0));
  
    timeline
      .to(position, {
        x: targetPosition.x, y: targetPosition.y, z: targetPosition.z,
        duration: 1, ease: "expo.inOut",
        onUpdate: () => runeMesh.setTranslation(position, true),
        onComplete: () => updateRuneOpacity(index, 1)
      }, 0)
      .to(rotation, {
        x: targetQuaternion.x, y: targetQuaternion.y, z: targetQuaternion.z, w: targetQuaternion.w,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setRotation(rotation, true)
      }, 0)
      .to(rotation, {
        x: targetQuaternion2.x, y: targetQuaternion2.y, z: targetQuaternion2.z, w: targetQuaternion2.w,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setRotation(rotation, true)
      })
      .to({}, { duration: 0.5, })
      .to(position, {
        x: targetPosition2.x, y: targetPosition2.y, z: targetPosition2.z,
        duration: 1, ease: "power2.inOut",
        onUpdate: () => runeMesh.setTranslation(position, true)
      })
      .to({}, { duration: 0.1,
        onComplete: () => {
          gsap.to(rotation, {
            x: targetQuaternion3.x, y: targetQuaternion3.y, z: targetQuaternion3.z, w: targetQuaternion3.w,
            duration: 0.5, ease: "power2.inOut",
            onUpdate: () => runeMesh.setRotation(rotation, true),
            onComplete: () => { setRuneOnClick(true) }
          });
        }
      }, 2.5);
  };
  
  //const [hovered, setHovered] = useState(false);
  const handlePointerOver = (meshRef, status) => {
    if (meshRef.current) {
      gsap.to(meshRef.current.children[0].material.color, {
        r: status ? 1 : 0, 
        g: status ? 1 : 0.13, 
        b: status ? 1 : 0, 
        duration: 0.5,
    });
    }
  };

  const showDetailsOfRune = ()=>{
  
    // gsap.to(camera.position, { x: 0, y: 0, z: 25,
    //   duration: 1,  // Duration in seconds
    //   ease: "power3.inOut",
    //   onUpdate: () => {camera.lookAt(0,0,0); } });

    // const calculateTargetPosition=(size)=> {
    //   const vectorArray = [];
    //   if (isMobile)
    //     for (let i = 0; i < size; i++) {
    //       vectorArray.push(new THREE.Vector3(0,5+(-i * 50), 13.5));
    //     }
    //   else
    //     for (let i = 0; i < size; i++) {
    //       vectorArray.push(new THREE.Vector3(-14, -i * 50, 0));
    //     }
    //   return vectorArray;
    // }
    // const targetPosition = calculateTargetPosition(runeSelectedCount); 

    const targetPosition =  new THREE.Vector3(0, 14, 5);

    const targetQuaternion = new THREE.Quaternion().setFromEuler( new THREE.Euler(Math.PI /4, Math.PI / 2, 0));
    
    const position = selectedRunesDetails[0].rigidBodyRef.current.translation();
    let rotation =  selectedRunesDetails[0].rigidBodyRef.current.rotation();

    gsap.timeline().to(position, {
      x: targetPosition.x, y: targetPosition.y, z: targetPosition.z,
      duration: 1, ease: "expo.inOut",
      onUpdate: () => selectedRunesDetails[0].rigidBodyRef.current.setTranslation(position, true),
      onComplete:()=>setRuneScale(0)
    })
    .to(rotation, {
      x: targetQuaternion.x, y: targetQuaternion.y, z: targetQuaternion.z, w: targetQuaternion.w,
      duration: 1, ease: "power2.inOut",
      onUpdate: () =>{selectedRunesDetails[0].rigidBodyRef.current.setRotation(rotation, true)}
    },0);
    

    console.log(bgMaterialRef.current);

    bgMaterialRef.current.transparent = true;
    gsap.to(bgMaterialRef.current, {
      opacity: 0,
      duration: 2,
      ease: 'none',  // You can change the easing function according to your preference
      onComplete: () => { bgMaterialRef.current.opacity=0; console.log("Animation complete")}
    });

    // selectedRunesDetails.map((item, index) => {
    //   const rigidBodyRef = item.rigidBodyRef.current;
    //   if (isMobile) setRuneScale(0);
    //   else setRuneScale(0);

    //   const position = rigidBodyRef.translation();
    //   let rotation = rigidBodyRef.rotation();
    //   // console.log("rotation before gsap ", rotation)
    //   // console.log("target rotation before gsap ", targetQuaternion)
    //   gsap.timeline().to(position, {
    //       x: targetPosition[index].x, y: targetPosition[index].y, z: targetPosition[index].z,
    //       duration: 1, ease: "expo.inOut",
    //       onUpdate: () => rigidBodyRef.setTranslation(position, true)
    //     })
    //     .to(rotation, {
    //       x: targetQuaternion.x, y: targetQuaternion.y, z: targetQuaternion.z, w: targetQuaternion.w,
    //       duration: 1, ease: "power2.inOut",
    //       onUpdate: () =>{rigidBodyRef.setRotation(rotation, true)}
    //     },0)
    // });
  };


  const hideRemainingRunes = ()=>{
    // console.log("Hide Remaining runes ", selectedRunesDetails);
    let _selecteRuneIndex = [];
    selectedRunesDetails.map((item, index) => {
      _selecteRuneIndex.push(item.index);
    });

    console.log("selected rune index ", _selecteRuneIndex);

    setTimeout(() => {
      visibleRunes.map((item,index) => {
        // console.log(index);
        if(!_selecteRuneIndex.includes(index))
          visibleRunes[index] = false;
        
      });
    }, 2000);
 



    runes.map((item, i)=>{
      if(!_selecteRuneIndex.includes(i))
        {
          const position = item.ref.current.translation();
          let _body = item.ref.current;

          if(_body.isSleeping())
            _body.wakeUp()
          _body.applyImpulse({x:-Math.random()*100-50, y:10+Math.random()*20, z:0})
        }
    })
  };

  const createModifiedScene = useCallback((index, opacity) => {
    const clonedScene = scene.clone();
    
    let symbolIndex = index;    
    clonedScene.userData = {
      ...clonedScene.userData,
      name: RUNE_NAMES[symbolIndex],
      index: symbolIndex,
    };

    clonedScene.traverse((child) => {
      if (child.isMesh) {
        if (child.name === "Stone") {
          child.material = child.material.clone();
          child.material.color.set("darkgreen");
          child.material.map = runeStoneTexture;
          child.material.roughness = 0.15;
          child.material.metalness = 0.5;
        } else {
          child.material = child.material.clone();
          child.material.map = symbolTextures[symbolIndex];
          symbolIndex++;
          child.material.transparent = true;
          child.material.opacity = opacity;
        }
      }
    });
    return clonedScene;
  }, [scene, runeStoneTexture, symbolTextures]);


  const runes = runeData.map(({ position, velocity, opacity }, i) => {
    const rigidBodyRef = useRef();
    const primitiveRef = useRef();

    return (
      visibleRunes[i] && (
        <RigidBody
          ref={rigidBodyRef}
          type={kinematicRunes[i] ? "kinematicPosition" : "dynamic"}
          colliders={"hull"} ccd={false} restitution={0} friction={1}
          position={position} linearVelocity={velocity}
          key={i}
          scale={1.2} linearDamping={1} angularDamping={1}
        >
         <Float enabled={isRuneSelectionComplete} speed={1.8} rotationIntensity={1.5} floatIntensity={1.5} floatingRange={[-3, -2]} >
          <primitive
            ref={primitiveRef} // Set the ref here
            object={createModifiedScene(i, opacity)}
            scale={runeScale}
            onClick={(event) =>
              enableRuneOnClick && !runeClicked[i] && handleRuneClick(event, i, rigidBodyRef, primitiveRef)
            }
            onPointerOver={()=> { enableRuneOnClick && !runeClicked[i] && handlePointerOver(primitiveRef,true)}}
            onPointerOut={()=> { enableRuneOnClick && !runeClicked[i] && handlePointerOver(primitiveRef,false)}}
          />
           </Float>
        </RigidBody>
      )
    );
  });

  function Boundary() {
    return (
      <>
        <CuboidCollider type="fixed" args={[20, 20, 2]} position={[-0, 0, -10]} /> 
        <CuboidCollider type="fixed" args={[20, 20, 2]} position={[0, 0, 5]} /> 
        <CuboidCollider type="fixed" args={[2, 20, 14]} position={[-11, 0, 0]} rotation={[0, -Math.PI / 10, 0]} />
        <CuboidCollider type="fixed" args={[2, 20, 14]} position={[11, 0, 0]} rotation={[0, Math.PI / 10, 0]} />
      </>
    );
  }

 const raycastRef = useRef();
 const boxRef = useRef();
  return (
    <>
      
      {/* <Stage adjustCamera={false} intensity={0.5}  environment="city"> */}
      { !isRuneSelectionComplete &&  <HandAnimation />}
      <AudioControl />
      <ScrollControls id="mainScroll" enabled={isRuneSelectionComplete} pages={scrollPageCount} damping={0}>
        <Scroll>
          <Physics gravity={[0, -20, 0]}>
            {runes}
            <RigidBody type="fixed" restitution={0.0} friction={1} linearDamping={1} angularDamping={1}>
              {!isRuneFallingComplete && <Boundary />}
              <CuboidCollider type="fixed" args={[50, 2, 50]} position={[0, -4, 0]} />
            </RigidBody>
          </Physics>
        </Scroll>
        <Scroll html id="scrollControlls">
          {scrollPageCount > 0 && (
            <RuneDetails
              mode={mode}
              data={backEndData}
              selectedRunes={selectedRunesDetails}
              meshRef={raycastRef}   
              symbolTextures={symbolTextures}           
            />
          )}
        </Scroll>
      </ScrollControls>

      {  (

        <mesh
          position={[0, -6, -7]}
          rotation-x={(-Math.PI / 2) * 0.55}
          scale={[60*1.5,60,60]}
        >
          <planeGeometry />
          <meshStandardMaterial ref={bgMaterialRef} transparent map={bgTexture} />
        </mesh>
      )}
    </>
  );
}


 


