import { Environment, Float, OrbitControls, Scroll, ScrollControls, Sparkles, SpotLight, useGLTF, useScroll, useTexture, } from "@react-three/drei";
import "../styles/style.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import useStore from '../utilities/useStore';
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as THREE from "three"; // Import THREE.js
import { Canvas, useFrame, useThree } from "@react-three/fiber";



export function RuneDetails({ mode, data, selectedRunes , meshRef , symbolTextures }) {
   
  const showPopup = useStore((state) => state.showPopup);
  const restartGame = useStore((state) => state.restartGame);
  const configData = useStore((state) => state.configData);
  const setReloadState = useStore((state) => state.setReloadState);

  const handleShow = () => showPopup(true);
  const handleRestart = () => setReloadState(Math.random());

  const [runeDetail, setRuneDetail] = useState([]);

  const addRuneDetails = (name, personalMeaning, generalMeaning) => {
    const newRuneDetail = { name, personalMeaning, generalMeaning };
    setRuneDetail((prevRuneDetail) => [...prevRuneDetail, newRuneDetail]); // Spread existing people and add new person
  };

  useEffect(() => {
    selectedRunes.map((item, index) => {
      
      const name = item.primitiveRef.current.userData.name;
      console.log("Name is ", name);
      const dataIndex = data.findIndex((rune) => rune.name === name);
      const personalMeaning = data[dataIndex][mode.runes[index]["personal_meaning"]];
      const generalMeaning = data[dataIndex][mode.runes[index]["general_meaning"]];
      addRuneDetails(name, personalMeaning, generalMeaning);
    });

    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'));
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);
    },100)

  }, [selectedRunes]);

  const {camera } = useThree();

 
  
  // useEffect(()=>{
  // if(isMobile)
  //   setTimeout(()=>{
  // setTotalHeight(document.getElementById("card0").parentNode.parentNode.clientHeight);
  // setCardHeight1(document.getElementById("card0").parentNode.clientHeight);
  // setCardHeight2(document.getElementById("card1").parentNode.clientHeight);
  //     console.log("client height ",document.getElementById("card0").parentNode.clientHeight);
  //     // const height = parseInt( document.getElementById("card0").clientHeight);
  //     let height = 1;
  //     // selectedRunes[1].rigidBodyRef.current.setTranslation(new THREE.Vector3(0,-height/10, 0), true)
  //     selectedRunes.map((item, index) => {
  //       const rigidBody = item.rigidBodyRef.current;
  //       let spacing = -5;
  //       const multiplier = 0.05; // lower the value closer the runes
       
  //       if(index != 0){
  //         console.log("height ", document.getElementById("card"+(index-1)).parentNode.clientHeight);
  //         height += parseInt( document.getElementById("card"+(index-1)).parentNode.clientHeight);
  //        spacing = index*2.1;
  //       }
  //       // console.log("height ",height);
  //       rigidBody.setTranslation(new THREE.Vector3(0,-(height*multiplier)-spacing, 13.5), true)
  //     });
  //   },1000);
  // },[]);


  const { scene } = useGLTF("../runes/Rune1.glb");
  const targetQuaternion = new THREE.Quaternion().setFromEuler( new THREE.Euler(Math.PI/2, Math.PI/2, 0) );
 
  const runeStoneTexture = useTexture("../runes/maintex.jpg");
  const createModifiedScene = useCallback((index) => {
    const clonedScene = scene.clone();
    

   // let symbolIndex = index;
    clonedScene.scale.set(5,5,5);
  console.log("index ", index);

    clonedScene.traverse((child) => {
      if (child.isMesh) {
        if (child.name === "Stone") {
          child.material = child.material.clone();
          child.material.color.set("darkgreen");
          child.material.map = runeStoneTexture;
          child.material.roughness = 0.15;
          child.material.metalness = 0.5;
        }
         else {
          child.material = child.material.clone();
          child.material.map = symbolTextures[selectedRunes[index].index];
          // symbolIndex++;
          child.material.transparent = true;
          // child.material.opacity = opacity;
        }
      }
    });
    return clonedScene;
  }, []);

  


  return (
    <>
<Container fluid className={"vh-100 d-flex flex-column"}>
  <Row className="flex-grow-1" style={{ justifyContent: "center" }}>
    <Col md={8}>
      {runeDetail.map((page, index) => (
        <div
          key={index}
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            minHeight: '100vh',
          }}
        >
           <h3 className="text-center mb-4">{page.name}</h3> {/* Title heading */}
        <div id={"card"+index} style={{ height: '20vh', objectFit: 'cover', width: '100%' }}>
              <Canvas>
                {/* <OrbitControls/> */}
                <directionalLight   position={[-20, 15, 10]} intensity={7} />
                <ambientLight intensity={5}/>
                <Environment preset="forest"/>
                <Float  speed={1.8} rotationIntensity={1.5} floatIntensity={1.5} floatingRange={[-0.5, 1]} >
              <primitive
              object={createModifiedScene(index)}
              rotation={[Math.PI/2, Math.PI/2, 0]}
              scale={3}
            />
            </Float>
              </Canvas>
        </div>
          <Card   className="text-black bg-transparent mb-4 w-100" style={{   border: 0,opacity: 1}}>
            <Card.Body>
              {/* <Card.Title>{page.name}</Card.Title> */}
              <Card.Text style={{ fontWeight: "bold", fontSize: configData.FONT_SIZE.SUB_HEADING }}>
                Persönliche Bedeutung:
              </Card.Text>
              <Card.Text style={{ fontSize: configData.FONT_SIZE.BODY }}>
             
                {page.personalMeaning}
             
              </Card.Text>
              <Card.Text style={{ fontWeight: "bold", fontSize: configData.FONT_SIZE.SUB_HEADING }}>
                Allgemeine Bedeutung:
              </Card.Text>
              <Card.Text style={{ fontSize: configData.FONT_SIZE.BODY }}>
                {page.generalMeaning}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      ))}
    </Col>
  </Row>
  <Row className="justify-content-center mt-4">
    <Col xs="auto">
      <Button variant="primary" onClick={handleShow}>Change Mode</Button>
    </Col>
    <Col xs="auto">
      <Button variant="secondary" onClick={handleRestart}>Restart</Button>
    </Col>
  </Row>
</Container>
   </>
  ); }
