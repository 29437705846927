// useStore.js
import create from 'zustand';

const useStore = create((set,get) => ({
  isPopEnabled: false,
  showPopup: (value) => set({ isPopEnabled: value }),
  navigate: null, // Placeholder for the navigate function
  setNavigate: (navigate) => set({ navigate }),
  restartGame: () => {
    const { navigate } = get();
    if (navigate) {
      navigate('/'); // Example navigation path
    } else {
      console.error('Navigate function is not set');
    }
  },
  configData: null,
  setConfigData: (data) => set({ configData: data }),
  reloadState: 1,
  setReloadState: (data) => set({ reloadState: data }),

}));

export default useStore;
