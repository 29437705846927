import * as THREE from 'three';

class RunesAudioManager {
  constructor() {
    this.listener = new THREE.AudioListener();
    this.audioMap = new Map();
  }

  addAudio(id, url, { loop = false, volume = 1 } = {}) {
    
    if (this.audioMap.has(id)) {
      console.warn(`Audio with id ${id} already exists.`);
      return;
    }

    const audio = new THREE.Audio(this.listener);
    const audioLoader = new THREE.AudioLoader();

    audioLoader.load(url, (buffer) => {
      //console.log("url ", url);
      audio.setBuffer(buffer);
      audio.setLoop(loop);
      audio.setVolume(volume);
      this.audioMap.set(id, audio);
    });
  }

  playAudio(id) {
    const audio = this.audioMap.get(id);
    // console.log("this.audioMap ", this.audioMap);
    // console.log("this.audioMap ", audio);
    if (audio && !audio.isPlaying) {
      audio.play();
      // console.log("this.audioMap ", this.audioMap);
    }
  }

  pauseAudio(id) {
    const audio = this.audioMap.get(id);
    if (audio && audio.isPlaying) {
      audio.pause();
    }
  }

  stopAudio(id) {
    const audio = this.audioMap.get(id);
    if (audio && audio.isPlaying) {
      audio.stop();
    }
  }

  setPlaybackRate(id, rate) {
    const audio = this.audioMap.get(id);
    if (audio) {
      audio.playbackRate = rate;
      console.log(`Audio ${id} playback rate set to ${rate}`);
    }
  }

  removeAudio(id) {
    const audio = this.audioMap.get(id);
    if (audio) {
      audio.stop();
      this.this.audioMap.delete(id);
    }
  }

  setVolume(id, volume) {
    const audio = this.audioMap.get(id);
    if (audio) {
      audio.setVolume(volume);
    }
  }

  setLoop(id, loop) {
    const audio = this.audioMap.get(id);
    if (audio) {
      audio.setLoop(loop);
    }
  }

  getListener() {
    return this.listener;
  }
}

export default RunesAudioManager;
